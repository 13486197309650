import { useDispatch, useSelector } from 'react-redux';
import { getMonitorDataSelector } from '../../redux/slices/monitorSlice';
import React, { useState } from 'react';
import TimeSlotSelector from '../../components/TimeSlotSelector';
import SupplierDropdown from '../../components/SupplierDropdown'; // Import the SupplierDropdown component
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import { submitSupplierData } from '../../redux/slices/supplierSlice';

const Suppliers = () => {
    const dispatch = useDispatch();
    const format = 'YYYY-MM-DDTHH:mm:ss.SSS'
    const [timeSlots, setTimeSlots] = useState([{ startTime: null, endTime: null }]);
    const [fromDate, setFromDate] = useState(dayjs());
    const [toDate, setToDate] = useState(dayjs());
    const [message, setMessage] = useState(null); 
    const [selectedSupplier, setSelectedSupplier] = useState('None');
    const monitorData = useSelector(getMonitorDataSelector);
    const custId = useSelector((state) => state.auth.id);

    const handleStartChange = (index, newValue) => {
        const updatedSlots = [...timeSlots];
        updatedSlots[index].startTime = newValue;
        setTimeSlots(updatedSlots);
    };

    const handleEndChange = (index, newValue) => {
        const updatedSlots = [...timeSlots];
        updatedSlots[index].endTime = newValue;
        setTimeSlots(updatedSlots);
    };

    const handleDateChange = (newValue) => {
        if (validateDateChange(newValue, 'fromDate')) {
            setFromDate(newValue);
            if (toDate.diff(newValue, 'day') > 6) {
                let adjust = toDate.diff(newValue, 'day') - 6;
                setToDate(toDate.subtract(adjust, 'day'));
            }
        }
    };

    const validateDateChange = (newValue, type) => {
        if (type === 'fromDate') {
            return newValue.isBefore(dayjs().add(1, 'day')); // Allow today and before
        }
        return true;
    };

    function isValidDate(date) {
        const parsedDate = new Date(date);
        return !isNaN(parsedDate.getTime()); 
    }

    const hasErrors = () => {
        const start_date = timeSlots.length > 0 ? timeSlots[0].startTime : null;
        const end_date = timeSlots.length > 0 ? timeSlots[0].endTime : null;
        if (!isValidDate(start_date)) {
            console.log('Start Date is Invalid');
            return true; 
        }
        if (!isValidDate(end_date)) {
            console.log('End Date is Invalid');
            return true;  
        }
        return timeSlots.some((slot) => {
            const start = slot.startTime?.getTime();
            const end = slot.endTime?.getTime();
            return start >= end || start === null || end === null;
        });
    };

    const areAllFieldsFilled = () => {
        return timeSlots.every((slot) => slot.startTime && slot.endTime);
    };

    const addTimeSlot = () => {
        if (!hasErrors() && areAllFieldsFilled()) {
            setTimeSlots([...timeSlots, { startTime: null, endTime: null }]);
        }
    };

    const removeTimeSlot = (index) => {
        const updatedSlots = timeSlots.filter((_, i) => i !== index);
        setTimeSlots(updatedSlots);
    };

    const handleSupplierChange = (value) => {
        setSelectedSupplier(value);
    };

    const isSubmitDisabled = () => {
        const isSupplierSelected = selectedSupplier !== 'None';
        const isFromDateValid = fromDate.isValid();
        const isToDateValid = toDate.isValid();
        const areTimeSlotsValid = !hasErrors() && areAllFieldsFilled();
        
        return !(isSupplierSelected && isFromDateValid && isToDateValid && areTimeSlotsValid);
    };
    

    const handleSubmit = () => {
        
        const requestBody = {
            customerId:custId,
            supplier: selectedSupplier,
            date: fromDate.format(format), 
            timeSlots: timeSlots.map((slot) => ({
                startTime: slot.startTime ? dayjs(slot.startTime).format('HH:mm') : null, 
                endTime: slot.endTime ? dayjs(slot.endTime).format('HH:mm') : null
            }))
        };
        console.log('Request Body:', requestBody);
        if (selectedSupplier === 'None') {
            alert('Please select a supplier before submitting.');
            return;
        }
        if (!isSubmitDisabled()) {
            dispatch(submitSupplierData(requestBody))
                .then((response) => {
                    if (response.meta.requestStatus === 'fulfilled') {
                        setMessage('Submission successful');
                        setTimeout(() => setMessage(null), 3000);  // Message disappears after 3 seconds
                        setSelectedSupplier('None');
                        setFromDate(dayjs());
                        setTimeSlots([{ startTime: null, endTime: null }]);
                    } else {
                        const errorMessage ='No predictions found for the given date and time';
                        setMessage(errorMessage); 
                        setTimeout(() => setMessage(null), 3000);
                    }
                });
        } else {
            console.log('Form is not valid. Please complete all fields.');
        }
    };

    return (
        <Grid container spacing={4} style={styles.container}> {/* Increased spacing */}
        {message && (
          <Grid item xs={12}>
            <Typography variant="body1" color="primary" style={{ fontSize: '18px' }}>
              {message}
            </Typography>
          </Grid>
        )}

    <Grid item xs={12} sm={12} md={6}>
        <Typography variant="h6" gutterBottom style={styles.dateText}>
            Select Date
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DatePicker
        inputFormat="DD/MM/YYYY"
        value={fromDate}
        onChange={(newValue) => {
            if (newValue.isValid()) {
                setFromDate(newValue);
            }
        }}
        renderInput={(params) => (
            <TextField 
                {...params} 
                variant="standard" 
                sx={{
                    fontSize: '20px', // Increase font size
                    padding: '0px',  // Increase padding
                    width: '50%',    // Ensure full width
                    height: '50px',   // Increase height for better readability
                    '& .MuiInputBase-input': {
                        fontSize: '20px',  // Increase input text size
                    },
                    '& .MuiInput-root': {
                        padding: '5px 10px', // Adjust internal padding of the input field
                    }
                }} 
            />
        )}
        maxDate={dayjs()}
        shouldDisableDate={(date) => date.isAfter(dayjs())}
    />
</LocalizationProvider>


        <Typography variant="h6" gutterBottom style={{ ...styles.supplierText, fontSize: '25px' }}>
            Select Supplier
        </Typography>
        <SupplierDropdown
            selectedSupplier={selectedSupplier}
            handleSupplierChange={handleSupplierChange}
            suppliers={monitorData.suppliers}
        />
    </Grid>

    <Grid item xs={12} sm={12} md={6}>
        <Typography variant="h6" gutterBottom style={{fontSize: '25px' }}>
            Select Time Slots
        </Typography>
        <div style={styles.timeSlotContainer}>
            {timeSlots.map((slot, index) => (
                <Grid container spacing={2} alignItems="center" key={index} style={styles.timeSlot}>
                    <Grid
                        item
                        xs={10} // Use less space for the time selector
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                    >
                        <TimeSlotSelector
                            startTime={slot.startTime}
                            endTime={slot.endTime}
                            onStartChange={(newValue) => handleStartChange(index, newValue)}
                            onEndChange={(newValue) => handleEndChange(index, newValue)}
                        />
                    </Grid>
                    {index > 0 && (
                        <Grid
                            item
                            xs={2} // Ensure button takes up the right amount of space
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end', // Align the button to the right
                                alignItems: 'center', // Vertically center
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={() => removeTimeSlot(index)}
                                style={styles.removeButton}
                            >
                                Remove
                            </Button>
                        </Grid>
                    )}
                </Grid>
            ))}
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    onClick={addTimeSlot}
                    style={styles.addButton}
                    disabled={hasErrors() || !areAllFieldsFilled()}
                >
                    Add Time Slot
                </Button>
            </Grid>
        </div>
    </Grid>

    <Grid item xs={12} style={styles.submitButtonContainer}>
        <Button
            variant="contained"
            onClick={handleSubmit}
            style={styles.submitButton}
            disabled={isSubmitDisabled()}
        >
            Submit
        </Button>
    </Grid>
</Grid>

      );
      
      
};

const styles = {
    container: {
        padding: '20px',
    },
    timeSlotContainer: {
        maxHeight: '300px',
        overflowY: 'auto',
        overflowX: 'hidden',
        marginBottom: '10px',
    },
    timeSlot: {
        marginBottom: '15px',
    },
    removeButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    addButton: {
        marginTop: '10px',
        
    },
    removeButton: {
        backgroundColor: 'red',
        color: 'white',
        marginLeft: '5px',
    },
    supplierText: {
        paddingBottom: '10px',
        paddingTop:'20px'
    },
    dateText: {
        marginBottom: '4px',
        fontSize: '25px'
        
    },
    submitButtonContainer: {
        display: 'flex',
        justifyContent: 'center', // Ensures the button is centered
        marginTop: '20px',
    },
    submitButton: {
        backgroundColor: 'green',
        padding: '12px 30px', // Increases button size
        fontSize: '16px',      // Increases font size for better readability
        width: '200px',        // Makes the button wider
        color: 'white',
        fontWeight: 'bold',
    },
};

export default Suppliers;
