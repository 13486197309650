import React from 'react';
import { Typography, Box, AppBar, Toolbar ,Button } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { logout } from '../redux/slices/authSlice';
import { styled } from '@mui/material/styles';
import logo from '../assests/Ecoorbit Logo.png';
//import TextSwitch from '../components/TextSwitch';

const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,width:'100%',
    overflow: 'auto',
    paddingTop: '20px',
    paddingBottom: '20px',
    paddingLeft: '40px',
    paddingRight: '40px',
    minHeight: '90vh',
    [theme.breakpoints.up('md')]: {
        paddingLeft: '120px',
        paddingRight: '120px',
    }
}));

const FooterStyle = styled('div')(({ theme }) => ({
    width: "100%",
    backgroundColor: theme.palette.secondary.main,
    color: '#BBBBBB',
    padding: "15px",
}));


function DashboardLayout(props) {
    const { isAuthenticated } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const handleLogout = () => {
      localStorage.removeItem('authToken');
      localStorage.removeItem('requestData');
        dispatch(logout());
        navigate('/login');
    };

    /*let navigate = useNavigate();
    const location = useLocation();

    const getInitialTabState = () => {
        let initalTabState = [
            {
                "label": "Monitor",
                "selected": (location.pathname === '/')
            },
            {
                "label": "Assess Quality",
                "selected": (location.pathname === '/quality')
            }
        ]
        return initalTabState
    }

    const [tabState, setTabState] = useState(getInitialTabState());


    const handleTabSwitch = (obj) => {
        setTabState(obj.value);
        const selectedLabel = obj.selectedLabel;
        if (selectedLabel === "Monitor") {
            navigate("/");
        } else if (selectedLabel === "Assess Quality") {
            navigate("/quality");
        }
    }*/

    return (
        <div>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" elevation={0} color='secondary'>
                    <Toolbar>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <img src={logo} alt="Company Logo" style={{ height: '70px', marginRight: '0px' ,marginLeft:'20px' }} /> {/* Adjusted marginRight */}
                            <Typography variant="h3" sx={{
                                fontFamily: "Poppins",
                                fontWeight: "700",
                                lineHeight: "32px",
                                ml: "5px",  // Adjusted margin-left for EcoMonitor
                                '@media (min-width:900px)': {
                                    ml: "20px",  // Adjusted for larger screens
                                },
                                mt: "26px",
                                mb: "16px",
                                letterSpacing: "2px"
                            }}>
                                EcoMonitor
                            </Typography>
                        </Box>

                        <Typography variant="caption" sx={{
                            fontFamily: "Poppins",
                            fontWeight: "400",
                            lineHeight: "32px",
                            ml: "5px",
                            mt: "20px",
                            color: "text.secondary"
                        }} >
                            by EcoOrbit
                        </Typography>

                        
                     {/* Right side content - Conditionally rendered based on authentication */}
            <Box sx={{ marginLeft: "auto", '@media (min-width:700px)': { mr: "49px" } }}>
            <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      lineHeight: "32px",
                    }}
                  >
                    Material Recovery Facility
                  </Typography>
              {isAuthenticated && (
                <>
                  {/* Material Recovery Facility - Only for authenticated users */}
                  
                  <Button
                    onClick={handleLogout}
                    sx={{ padding: 0, textTransform: "none" }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        lineHeight: "32px",
                        color: "text.secondary",
                        mt: "-6px"
                      }}
                    >
                      Logout
                    </Typography>
                  </Button>
                </>
              )}
            </Box>
          </Toolbar>
        </AppBar>
      </Box>

      <MainStyle>
        <Outlet />
      </MainStyle>

      {/* Footer */}
      <FooterStyle>
        Copyrights reserved - EcoOrbit Solutions 2022
      </FooterStyle>
    </div>
  );
}

export default DashboardLayout;