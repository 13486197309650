import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginSuccess, loginFailure } from './../../redux/slices/authSlice';
import { useNavigate } from 'react-router-dom';

import { TextField, Button, Container, Box, Typography, CircularProgress, Paper } from '@mui/material';


  const LOGIN_POST_URL = `${process.env.REACT_APP_ECO_BASE_URL}/login`;
  const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
  
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isAuthenticated, error } = useSelector((state) => state.auth);
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);
  
      try {
        const response = await fetch(LOGIN_POST_URL, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email, password }),
        });
  
        const result = await response.json();
        if (result.success) {
          const id = result.id;  
          localStorage.setItem('authToken', result.token);
          dispatch(loginSuccess({ email: result.user.email, id: result.id }));
          navigate('/');
        } else {
          dispatch(loginFailure(result.message || 'Invalid credentials'));
        }
      } catch (error) {
        dispatch(loginFailure('Failed to connect to the server'));
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 130px)', 
        padding: '20px',width:'100%'
      }}>
        <Paper elevation={3} sx={{
          padding: 4,
          borderRadius: '15px',
          width: '100%',
          maxWidth: 500
        }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography component="h1" variant="h3" sx={{
              fontWeight: 'bold',
              textAlign: 'center',
              mb: 3
            }}>
              Login to EcoMonitor
            </Typography>

            {loading && <CircularProgress sx={{ marginBottom: 2 }} />}

            <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
  <Typography sx={{ marginBottom: 1, textAlign: 'left', fontWeight: '500', fontSize: '18px' }}>
    Email
  </Typography>
  <TextField
    label="Enter your email"
    variant="outlined"
    margin="normal"
    required
    fullWidth
    value={email}
    onChange={(e) => setEmail(e.target.value)}
    sx={{
      marginTop: 0,
      backgroundColor: '#fff',
      borderRadius: '8px',
      fontSize: '18px', // Increased font size for input text
      padding: '0px', // Increased padding
    }}
  />

  <Typography sx={{ marginBottom: 1, textAlign: 'left', fontWeight: '500', fontSize: '18px' }}>
    Password
  </Typography>
  <TextField
    label="Enter your password"
    variant="outlined"
    margin="normal"
    required
    fullWidth
    type="password"
    value={password}
    onChange={(e) => setPassword(e.target.value)}
    sx={{
      marginTop: 0,
      backgroundColor: '#fff',
      borderRadius: '8px',
      fontSize: '18px', // Increased font size for input text
      padding: '0px', // Increased padding
    }}
  />

  <Button
    type="submit"
    variant="contained"
    color="primary"
    sx={{
      mt: 3,
      mb: 2,
      width: '100%',
      padding: '14px', // Increased padding for the button
      borderRadius: '8px',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      textTransform: 'none',
      fontSize: '18px', // Increased font size for button text
      height: '50px',  // Optional: Keep button height consistent
    }}
    disabled={loading}
  >
    {loading ? 'Logging in...' : 'Login'}
  </Button>
</Box>

         

            {isAuthenticated && (
              <Typography sx={{
                marginTop: 2,
                color: 'green',
                fontWeight: '500'
              }}>
                Login successful! Welcome, {email}!
              </Typography>
            )}
          </Box>
        </Paper>
      </Box>

  );
};

  
  export default Login;