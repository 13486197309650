// import { useRoutes } from 'react-router-dom';
import Login from './pages/dashboard/Login';
import DashboardLayout from './layouts/DashboardLayout';
//import AssessQuality from './pages/dashboard/AssessQuality';
import Monitor from './pages/dashboard/Monitor';

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Assuming you're using Redux for state management
import { useRoutes } from 'react-router-dom';
import { loginSuccess, loginFailure } from './redux/slices/authSlice'; // Update with correct import
import axios from 'axios';

const VERIFY_TOKEN_URL = `${process.env.REACT_APP_ECO_BASE_URL}/validate-token`;
const validateToken = async (dispatch) => {
  const token = localStorage.getItem('authToken'); 
  
  if (token) {
    try {
      const response = await axios.post(VERIFY_TOKEN_URL, {  }, {
        headers: {
          'Authorization': `Bearer ${token}`, 
        },
      });
      const result =  response.data;

      if (result.valid) {
        
        dispatch(loginSuccess({id:result.userId})); 
      } else {
        
        localStorage.removeItem('authToken');
        dispatch(loginFailure('Invalid or expired token'));
      }
    } catch (error) {
      console.error('Token validation failed:', error);
     
      localStorage.removeItem('authToken');
      dispatch(loginFailure('Token validation failed'));
    }
  } else {
    
    dispatch(loginFailure('No token found'));
  }
};

export default function Router() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
   
    const token = localStorage.getItem('authToken');
    if (token) {
      validateToken(dispatch); 
    } else {
      dispatch(loginFailure('No token found')); 
    }
  }, [dispatch]); 

  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          path: '/',
          element: isAuthenticated ? <Monitor /> : <Login />, 
        },
        {
          path: 'login',
          element: <Login />,
        },
      ],
    },
    {
      path: '*',
      element: <Login />, 
    },
  ]);
}