import * as React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Typography, TextField, Slide, Grid } from '@mui/material';
import TextButton from '../../components/TextButton';
import { TransitionProps } from '@mui/material/transitions';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { startReportGeneration, getDownloadStatusSelector, getDownloadErrorSelector } from '../../redux/slices/monitorSlice';

// const Transition = React.forwardRef(function Transition(
//     props: TransitionProps & {
//         children: React.ReactElement<any, any>;
//     },
//     ref: React.Ref<unknown>,
// ) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function DownloadReportsDialog() {
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);
    const [errorView, setErrorView] = React.useState(false);
    const [downloading, setDownloading] = React.useState(false);
    const downloadStatus = useSelector(getDownloadStatusSelector);
    const downloadError = useSelector(getDownloadErrorSelector);

    let date = moment().startOf('day')
    const format = 'YYYY-MM-DDTHH:mm:ss.SSS'
    const [toDate, setToDate] = React.useState(dayjs(date.format(format)));
    const [fromDate, setFromDate] = React.useState(dayjs(date.subtract(30, 'day').format(format)));
    const custId = useSelector((state) => state.auth.id);
    
    const handleOpen = () => {
        setOpen(true);
    };

    React.useEffect(() => {
        if (downloadStatus === 'pending') {
            setDownloading(true);
        } else if (downloadStatus === 'succeeded') {
            setDownloading(false);
            setOpen(false);
        } else if (downloadStatus === 'failed') {
            setErrorView(true);
        }
    }, [downloadStatus, downloadError, dispatch])

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        setErrorView(false);
        setDownloading(false);
        setOpen(false);

    };

    const handleDownload = () => {
        setDownloading(true);

        let body = {
            cust_id: custId,
            start_date: fromDate.format(format),
            end_date: toDate.format(format)
        }

        dispatch(startReportGeneration(body));
    };

    return (
        <div>
            <TextButton text="Download" handleClick={handleOpen} />
            <Dialog open={open} onClose={handleClose} TransitionComponent={Transition} disableEscapeKeyDown={true} >
                <DialogTitle>Download Waste Analytics Reports</DialogTitle>
                <DialogContent>
                    {(!errorView && !downloading) && (
                        <>
                            <Typography variant="h6" component="div" sx={{ fontWeight: 400, mb: 3 }}>
                                The report will be generated for the selected date range.
                            </Typography>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <p>Select from date</p>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            inputFormat="DD/MM/YYYY"
                                            value={fromDate}
                                            onChange={(newValue) => {
                                                setFromDate(newValue)
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="standard" />}
                                            maxDate={dayjs()}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={6}>
                                    <p>Select to date</p>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            inputFormat="DD/MM/YYYY"
                                            value={toDate}
                                            onChange={(newValue) => {
                                                setToDate(newValue)
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="standard" />}
                                            shouldDisableDate={(date) => (date.diff(fromDate, 'day') < 0)}
                                            maxDate={dayjs()}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>


                        </>
                    )}
                    {(!errorView && downloading) && (
                        <>
                            <p>Generating the report, please wait for few seconds</p>
                        </>
                    )}
                    {errorView && (
                        <>
                            <p>{downloadError}</p>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    {!errorView && (
                        <>
                            <Button onClick={handleClose} color="primary" disabled={downloading}>
                                Cancel
                            </Button>
                            <Button onClick={handleDownload} color="primary" disabled={downloading}>
                                {downloading ? <CircularProgress size={24} /> : 'Download'}
                            </Button>
                        </>
                    )}
                    {errorView && (
                        <>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DownloadReportsDialog;
