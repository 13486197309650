import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { saveAs } from 'file-saver';

const initialState = {
    monitor: {
        total: null,
        chronology: [],
        composition: [],
        colorMap: null
    },
    suppliers: [],
    status: 'idle', // 'idle' 'loading' 'succeeded' 'failed'
    downloadStatus: 'idle',
    taskId: null,
    downloadError: null,
    error: null
}

const MONITOR_POST_URL = `${process.env.REACT_APP_ECO_BASE_URL}/monitor`;
const GENERATE_REPORT_URL = `${process.env.REACT_APP_ECO_BASE_URL}/startReportGeneration`;
const DOWNLOAD_REPORT_URL = `${process.env.REACT_APP_ECO_BASE_URL}/downloadReport`;



const getAuthToken = () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
        throw new Error('No authentication token found');
    }
    return token;
};

export const fetchMonitorData = createAsyncThunk('monitor/fetchMonitorData', async (body) => {
    const token = getAuthToken();
    console.log(body);

    const response = await axios.post(MONITOR_POST_URL, body, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    return response;
});


export const startReportGeneration = createAsyncThunk('monitor/startReportGeneration', async (body, { dispatch }) => {
    const token = getAuthToken();

    const response = await axios.post(GENERATE_REPORT_URL, body, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    const taskId = response.data.taskId;

    const downloadBody = {
        task_id: taskId,
        cust_id: body.cust_id || 1 
    };

    await dispatch(downloadReport(downloadBody));

    return response;
});

export const downloadReport = createAsyncThunk('monitor/downloadReport', async (body, { rejectWithValue }) => {
    const token = getAuthToken();
    let retryCount = 0;
    const maxRetries = 5;

    while (retryCount < maxRetries) {
        try {
            const response = await axios.post(DOWNLOAD_REPORT_URL, body, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                responseType: 'blob'
            });

            if (response.status === 200) {
                const filename = `report_${body.task_id}.xlsx`;
                console.log(response.data);
                saveAs(response.data, filename);
                return response.data;
            } else {
                retryCount++;
                if (retryCount < maxRetries) {
                    await new Promise((resolve) => setTimeout(resolve, 3000));
                }
            }
        } catch (error) {
            retryCount++;
            console.log(error);
            if (retryCount < maxRetries) {
                await new Promise((resolve) => setTimeout(resolve, 3000));
            }
        }
    }

    return rejectWithValue('Unable to download report. Please try again later.');
});

export const getMonitorStatusSelector = state => state.monitor.status
export const getMonitorDataSelector = state => state.monitor.monitor
export const getDownloadStatusSelector = state => state.monitor.downloadStatus
export const getDownloadTaskIdSelector = state => state.monitor.taskId
export const getMonitorErrorSelector = state => state.monitor.error
export const getDownloadErrorSelector = state => state.monitor.downloadError

const monitorSlice = createSlice({
    name: 'monitor',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMonitorData.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchMonitorData.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.monitor = action.payload.data
                //state.suppliers = action.payload.data.suppliers;

            })
            .addCase(fetchMonitorData.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(startReportGeneration.pending, (state, action) => {
                state.downloadStatus = 'loading'
            })
            .addCase(startReportGeneration.fulfilled, (state, action) => {
                state.taskId = action.payload.data.taskId
            })
            .addCase(startReportGeneration.rejected, (state, action) => {
                state.downloadStatus = 'failed'
                state.downloadError = action.error.message
                state.taskId = null
            })
            .addCase(downloadReport.pending, (state, action) => {
                state.downloadStatus = 'loading'
            })
            .addCase(downloadReport.fulfilled, (state, action) => {
                state.downloadStatus = 'succeeded'
                state.taskId = null
            })
            .addCase(downloadReport.rejected, (state, action) => {
                state.downloadStatus = 'failed'
                state.downloadError = action.error.message
                state.taskId = null
            })
    }
})

export default monitorSlice.reducer